import axios from "axios";
import { gapi } from "gapi-script";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io5";
import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";
import * as HiIcon from "react-icons/hi";
import GoogleLogin from "react-google-login";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { BACKEND_BASE_URL } from "../../Components/GlobalVariables";

import "../../Pages/UserSignUp & SignIn/customerRegForm.css";

const UserLogin = () => {
  const Email = useRef();
  const Password = useRef();

  const [toast_bg, setToast_bg] = useState("info");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState();
  const [userEmailError, setUserEmailError] = useState("");
  const [userPassError, setUserPassError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  let from = location.state?.from?.pathname || "/";

  const handleUserLogin = (e) => {
    const formdata = new FormData();
    formdata.append("email", Email.current.value);
    formdata.append("password", Password.current.value);

    axios
      .post(`${BACKEND_BASE_URL}/api/user/user-login`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        setUserEmailError("");
        setUserPassError("");
        if (response.data.status == 400) {
          const { message } = response.data;
          setMessage(message);
          setToast_bg("danger");
          setShow(true);
          if (response.data.errors) {
            const { email, password } = response.data.errors;
            setUserEmailError(email);
            setUserPassError(password);
          }
        } else {
          localStorage.setItem("user_id", response.data?.users?.id);
          localStorage.setItem("name", response.data?.users?.name);
          localStorage.setItem("email", response.data?.users?.email);
          localStorage.setItem("user_type", response.data?.users?.is_admin);
          localStorage.setItem("oauth_type", response.data?.users?.oauth_type);

          const { message } = response.data;
          setMessage(message);
          setToast_bg("success");
          setShow(true);
          setTimeout(() => {
            navigate(from, { replace: true });
          }, 1500);
        }
      });
    e.preventDefault();
  };

  // ========================Google Login======================

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "52699536198-l36ldpscdrqtske5ufqteg8f7dg4kfen.apps.googleusercontent.com",
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const googleSuccess = async (res) => {
    let userRes = res.profileObj;
    axios
      .post(
        `${BACKEND_BASE_URL}/api/user/registered-email/check`,
        { email: userRes.email },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        // console.log("Inside if",loggedUserInfo);
        if (response.data.user_count == 0) {
          // console.log("Inside if",loggedUserInfo);
          axios
            .post(
              `${BACKEND_BASE_URL}/api/user/google/registration/store`,
              { userRes, oauth_type: "google" },
              {
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((response) => {
              localStorage.setItem("name", response.data?.users?.name);
              localStorage.setItem("email", response.data?.users?.email);
              localStorage.setItem(
                "oauth_type",
                response.data?.users?.oauth_type
              );
              localStorage.setItem("user_type", response.data?.users?.is_admin);
            });
        } else {
          localStorage.setItem("name", response.data?.users?.name);
          localStorage.setItem("email", response.data?.users?.email);
          localStorage.setItem("oauth_type", response.data?.users?.oauth_type);
          localStorage.setItem("user_type", response.data?.users?.is_admin);
        }
      });
  };

  const googleFailure = (error) => {
    console.log(error);
    console.log("Google Sing In ha fracasado intentelo denuevo mas tarde");
  };

  // Password Show/Hide Toggle
  const [passType, setPassType] = useState("password");
  const handlePasswordType = () => {
    if (passType == "password") {
      setPassType("text");
    }
    if (passType == "text") {
      setPassType("password");
    }
  };

  return (
    <div className="form_wrapper" style={{ backgroundColor: "#f9fafb" }}>
      <Container className="container">
        <ToastContainer position="top-center" className="p-3 toast_msg">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            bg={toast_bg}
            autohide
          >
            <Toast.Body>
              <strong className="ms-3">{message ? message : ""}</strong>
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <div className="w-100 text-center">
          <img
            src={require("../../Assets/kepler-logo.png")}
            alt=""
            className="img-fluid "
          />
        </div>
        <Form id="form" className="form" onSubmit={handleUserLogin}>
          {/* onSubmit={handleSubmit} */}
          <h1>Log In</h1>
          {/* <h1> {message ? message : "Log In or create an account"}</h1> */}

          {/* ================== Email =================== */}
          <Form.Group className="form_group">
            <Form.Label>
              Email Address<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              id="email"
              placeholder="Enter email"
              name="email"
              ref={Email}
              required
            />
            <small className="small_msg">{userEmailError}</small>
          </Form.Group>

          {/* ============== Password ===================== */}
          <Form.Group className="form_group">
            <Form.Label>
              Password <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type={passType}
              id="password"
              placeholder="Enter password"
              name="password"
              ref={Password}
              required
            />
            <div className="eye_icon" onClick={handlePasswordType}>
              <AiIcons.AiFillEye size="1.2em" />
            </div>
            <small className="small_msg">{userPassError}</small>
          </Form.Group>

          <Button type="submit" className="w-100 login_btn">
            Log In
          </Button>
          <p className="mt-2">
            <Form.Check
              type="checkbox"
              label="By continuing, you agree to the Terms of Service and
              acknowledge our Privacy Policy"
            />
          </p>
          <center>or</center>
        </Form>
        <GoogleLogin
          render={(renderProps) => (
            <div className="d-flex justify-content-center">
              <Button
                className="w-75"
                style={{ borderRadius: "8px" }}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <BsIcons.BsGoogle size="1.5em" />
              </Button>
            </div>
          )}
          clientId="52699536198-l36ldpscdrqtske5ufqteg8f7dg4kfen.apps.googleusercontent.com"
          onSuccess={googleSuccess}
          onFailure={googleFailure}
          cookiePolicy="single_host_origin"
        />

        <h3 className="text-center my-3">
          <Link to="/user/signup" className="fs22 fw-bold text-black ">
            New here? Sign up!
          </Link>
        </h3>
      </Container>
    </div>
  );
};

export default UserLogin;
