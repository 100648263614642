import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import * as BiIcons from "react-icons/bi";
import * as FaIcons from "react-icons/fa";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../Components/GlobalVariables";
import Swal from "sweetalert2";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import JoditEditor from "jodit-react";
import config from "../../Components/JoditConfig";
import Parse from "html-react-parser";

const AllPosts = () => {
  const postTitle = useRef();
  const seoTitle = useRef();
  const seoDesc = useRef();
  const postImage = useRef();
  const postCategoryId = useRef();
  const postDesc = useRef();

  //=================================== Fetch Table Data ===================================

  const [allpost, setAllpost] = useState([]);
  const [allPostCategory, setAllallPostCategory] = useState([]);
  const [descValue, setDescValue] = useState();
  const [seoDescValue, setSeoDescValue] = useState();

  const renderAllPosts = async () => {
    await axios.get(`${BACKEND_BASE_URL}/api/admin/blog/posts`).then((res) => {
      setAllpost(res.data.posts);
      setAllallPostCategory(res.data.categories);
    });
  };

  useEffect(() => {
    renderAllPosts();
  }, []);

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  // Edit value
  const [editedPostTitle, setEditedPostTitle] = useState();
  const [editedSeoTitle, setEditedSeoTitle] = useState();
  const [editedSeoDesc, setEditedSeoDesc] = useState();
  const [editedPostImage, setEditedPostImage] = useState();
  const [editedPostCategory, setEditedPostCategory] = useState();
  const [editedPostDesc, setEditedPostDesc] = useState();

  // ============================= Add new data =============================
  const addNewData = (modalValue) => {
    setModalSize("lg");
    setModalData(modalValue);
    setModalShow(true);
  };

  // ============================= form submit to backend ======================

  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("title", postTitle.current.value);
    formdata.append("seo_title", seoTitle.current.value);
    formdata.append("meta_description", seoDesc.current.value);
    formdata.append("image", postImage.current.files[0]);
    formdata.append("category_id", postCategoryId.current.value);
    formdata.append("description", postDesc.current.value);

    axios
      .post(`${BACKEND_BASE_URL}/api/admin/blog/posts/store`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllPosts();
          setDescValue("", "html");
          setSeoDescValue("", "html")
          setModalShow(false);
        }
      });
    e.preventDefault();
  };

  // ===================== Edit data ==========================================

  const [Id, setId] = useState();

  const fetchDataForEdit = (modalValue, id) => {
    setFile([]);

    axios
      .get(`${BACKEND_BASE_URL}/api/admin/blog/posts/edit/${id}`)
      .then((res) => {
        const {
          id,
          title,
          image,
          category_id,
          description,
          seo_title,
          meta_description,
        } = res.data.post_info;
        setId(id);
        setEditedPostTitle(title);
        setEditedSeoTitle(seo_title);
        setEditedSeoDesc(meta_description);
        setEditedPostImage(image);
        setEditedPostCategory(category_id);
        setEditedPostDesc(description);
        setModalData(modalValue);
        setModalSize("lg");
        setModalShow(true);
      });
  };

  // ===================== Updated data to backend ===============================

  const updatedData = (e) => {
    const updatedPostImage = postImage.current.files[0];
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("title", postTitle.current.value);
    formdata.append("seo_title", seoTitle.current.value);
    formdata.append("meta_description", seoDesc.current.value);
    if (updatedPostImage) {
      formdata.append("image", postImage.current.files[0]);
    }
    formdata.append("category_id", postCategoryId.current.value);
    formdata.append("description", postDesc.current.value);

    axios
      .post(`${BACKEND_BASE_URL}/api/admin/blog/posts/update/${Id}`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        Swal.fire({
          icon: "success",
          text: response.data.message,
          confirmButtonColor: "#5eba86",
        });
        setModalShow(false);
        renderAllPosts();
      });

    e.preventDefault();
  };

  // ============================= View Single Data ==========================

  const [singlePostData, setSinglePostData] = useState([]);

  const showSinglePostData = (modalValue, id) => {
    setModalSize("");
    axios
      .get(`${BACKEND_BASE_URL}/api/admin/blog/posts/show/${id}`)
      .then((res) => {
        setModalSize("lg");
        setModalData(modalValue);
        setSinglePostData(res.data.post_info);
        setModalShow(true);
      });
  };

  // =============================== Delete Data ===============================
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}/api/admin/blog/posts/delete/${id}`)
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          renderAllPosts();
        });
    }
  };

  // Image Preview
  const [files, setFile] = useState([]);
  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };


  // ============================================= CHECKBOX ====================
  const [checkboxVal, setCheckboxVal] = useState([]);

  // const CheckHandler = (e) => {
  //   setCheckboxVal((prev) => [...prev, e.target.value]);
  // };

  // with add & remove filter
  const CheckHandler = (e) => {
    const value = e.target.value;
    setCheckboxVal((prev) =>
    checkboxVal.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, e.target.value]
    );
  };

 console.log(checkboxVal);

  return (
    <div className="main__container">
      <div className="content-wrapper">
        <div className="breadcrumb">
          <div className="breadcrumb-item">
            <Link to="/admin">Dashboard</Link>
            <Link to="#" className="before">
              Add
            </Link>
          </div>
        </div>

        <div className="col-md-12 mt-3">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>
                <div className="p-2">
                  <div className="card-header">
                    <Button
                      variant="success"
                      size="sm"
                      className="border-0"
                      onClick={() => addNewData("Add")}
                    >
                      Add &nbsp;
                      <span>
                        <AiIcons.AiOutlinePlusCircle />
                      </span>
                    </Button>

                    <hr />
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Title</th>
                      <th scope="col">Featured Image</th>
                      <th scope="col">Category</th>
                      <th scope="col">Handle</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allpost.map((data, index) => (
                      <tr key={index}>
                        <td> {index + 1}</td>
                        <td>{data.title}</td>
                        <td>
                          <img
                            className="img-thumbnail"
                            width={80}
                            height={50}
                            src={`${BACKEND_BASE_URL}/${data.image}`}
                            alt={data.title}
                          />
                        </td>
                        <td>{data?.category?.name}</td>
                        <td>
                          {/* view button */}
                          <button
                            onClick={() =>
                              showSinglePostData("View", data.id)
                            }
                            className="py-1 px-2 bg-info border-0 rounded-3 me-1 mb-1"
                          >
                            <FaIcons.FaEye
                              style={{
                                color: "white",
                              }}
                              title="View"
                              size="1.5em"
                            />
                          </button>
                          {/* edit button */}
                          <button
                            onClick={() => fetchDataForEdit("Edit", data.id)}
                            className="py-1 px-2 bg-warning border-0 rounded-3 me-1 mb-1"
                          >
                            <BiIcons.BiEdit
                              style={{
                                color: "white",
                              }}
                              title="Edit"
                              size="1.5em"
                            />
                          </button>
                          {/* delete button */}
                          <button
                            onClick={() => deleteData(data.id)}
                            className="py-1 px-2 bg-danger border-0 rounded-3 me-1 mb-1"
                          >
                            <MdIcons.MdDeleteForever
                              style={{
                                color: "white",
                              }}
                              title="Delete"
                              size="1.5em"
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title
                  className="text-black"
                  id="contained-modal-title-vcenter"
                >
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgIcons.CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {modalData === "Add" && (
                  <div className="">
                    <Form onSubmit={storeData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <Row className="py-3">
                              {/*  Post Title */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Title
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  ref={postTitle}
                                  defaultValue={editedPostTitle}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Title is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/*  SEO Title */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  SEO Title
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  ref={seoTitle}
                                  defaultValue={editedSeoTitle}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Title is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* SEO Description */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom02"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  SEO Description
                                </Form.Label>
                                <JoditEditor
                                  tabIndex={1}
                                  config={config}
                                  ref={seoDesc}
                                  value={seoDescValue}
                                />
                              </Form.Group>

                              {/* Product Image */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Featured Image{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  // required
                                  type="file"
                                  name="image"
                                  ref={postImage}
                                  onChange={handleImgPreview}
                                />

                                {files.map((file, key) => {
                                  return (
                                    <div key={key} className="Row">
                                      <span className="Filename">
                                        <img
                                          className="img-thumbnail mt-1"
                                          width={80}
                                          height={50}
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                                <Form.Control.Feedback type="invalid">
                                  Please choose an image
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* Post Category */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Select Category{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  required
                                  ref={postCategoryId}
                                >
                                  <option value="0">
                                    Select Main Category
                                  </option>
                                  {allPostCategory.map((data, index) => {
                                    return (
                                      <option key={index} value={data.id}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>

                              {/* Description */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom02"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Description
                                </Form.Label>
                                <JoditEditor
                                  tabIndex={1}
                                  config={config}
                                  ref={postDesc}
                                  value={descValue}
                                />
                              </Form.Group>

                              {/* Submit button */}
                              <button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                Save
                              </button>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}
                {modalData === "Edit" && (
                  <div className="">
                    <Form onSubmit={updatedData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <Row className="py-3">
                              {/*  Post Title */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Title
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  ref={postTitle}
                                  defaultValue={editedPostTitle}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Title is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/*  SEO Title */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  SEO Title
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  ref={seoTitle}
                                  defaultValue={editedSeoTitle}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Title is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* SEO Description */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom02"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  SEO Description
                                </Form.Label>
                                <JoditEditor
                                  tabIndex={1}
                                  config={config}
                                  ref={seoDesc}
                                  value={editedSeoDesc}
                                />
                              </Form.Group>

                              {/* Product Image */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Featured Image{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  // required
                                  type="file"
                                  name="image"
                                  ref={postImage}
                                  onChange={handleImgPreview}
                                />

                                {files.map((file, key) => {
                                  return (
                                    <div key={key} className="Row">
                                      <span className="Filename">
                                        <img
                                          className="img-thumbnail mt-1"
                                          width={80}
                                          height={50}
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                                {files.length == 0 && (
                                  <img
                                    className="img-thumbnail"
                                    width={80}
                                    height={50}
                                    src={`${BACKEND_BASE_URL}/${editedPostImage}`}
                                    alt={editedPostTitle}
                                  />
                                )}
                                <Form.Control.Feedback type="invalid">
                                  Please choose an image
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* Post Category */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Select Category
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  required
                                  ref={postCategoryId}
                                >
                                  <option value="0">
                                    Select Main Category
                                  </option>
                                  {allPostCategory.map((data, index) => {
                                    return (
                                      <option
                                        key={index}
                                        selected={
                                          data.id == editedPostCategory
                                            ? "selected"
                                            : ""
                                        }
                                        value={data.id}
                                      >
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>

                              {/* Description */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom02"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Description
                                </Form.Label>
                                <JoditEditor
                                  tabIndex={1}
                                  config={config}
                                  ref={postDesc}
                                  value={editedPostDesc}
                                />
                              </Form.Group>

                              {/* Submit button */}
                              <button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                Update
                              </button>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}
                {modalData === "View" && (
                  <>
                    <h4>{singlePostData.title}</h4>
                  <img
                    className="img-fluid d-flex m-auto"
                    src={`${BACKEND_BASE_URL}/${singlePostData.image}`}
                    alt=""
                  />
                  {Parse(`${singlePostData.description}`)}
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
      {/* <input
        type="checkbox"
        id="jane"
        name="jane"
        value="jane"
        onClick={CheckHandler}
      />
      <label htmlFor="jane">jane</label>
      <br />

      <input
        type="checkbox"
        id="Mike"
        name="Mike"
        value="Mike"
        onClick={CheckHandler}
      />
      <label htmlFor="Mike">Mike</label>
      <br />

      <input
        type="checkbox"
        id="board"
        name="board"
        value="board"
        onClick={CheckHandler}
      />
      <label htmlFor="board">board</label> */}
    </div>
  );
};

export default AllPosts;
