import React from 'react';

const AdminFooter = () => {
    return (
      <div className="copyright d-flex align-items-center justify-content-center text-white">
        <span className="p-3">Copyright &copy; 2022 Gastronomic. All rights reserved.</span>
      </div>
    );
  };
  

export default AdminFooter;