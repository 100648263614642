import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import { NavLink } from "react-router-dom";
const Footer = (props) => {
  return (
    <div className="footer">
      {/* mid_footer */}
      <div className="mid_footer">
        <Container fluid="xl" className="py-5">
          <Row>
            <Col sm={12} className="text-center">
              <h5 className="mb-4 f_bold">Links</h5>

              <ul className="flex_center flex-wrap footer_link_section">
                <li className="me-3">
                  <NavLink to="/contact-us" className="text_white pe-3">
                    Contact us
                  </NavLink>
                </li>
                <li className="me-3">
                  <NavLink to="/privacy-policy" className="text_white pe-3">
                    Privacy Policy
                  </NavLink>
                </li>
                <li className="me-3">
                  <NavLink to="/copyright-policy" className="text_white pe-3">
                    Copyright policy
                  </NavLink>
                </li>
                <li className="me-3">
                  <NavLink to="/cookies-policy" className="text_white pe-3">
                    Cookies Policy
                  </NavLink>
                </li>
                <li className="me-3">
                  <NavLink to="/terms-of-service" className="text_white pe-3">
                    Terms of service
                  </NavLink>
                </li>
                <li className="me-3">
                  <NavLink to="/update-consent" className="text_white pe-3">
                    Update consent
                  </NavLink>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      {/* bottom_footer */}
      <div className="bottom_footer">
        <Container fluid="sm" className="text-center py-3">
          {/* <span>Copyright © 2022 RRKABLE. All Right Reserved | Developed by <a className='mb-2 mb-sm-0' href="https://sarfaa.com/" target="_blank" rel="noreferrer">SARFAA.</a></span> */}

          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <small>
              <span style={{ fontSize: "17px" }}>2022</span> KEPLERAGE, All
              Rights Reserved.
            </small>
            {/* <small style={{fontSize: '11px'}}>DEVELOPED  <span className='mx-1'>BY</span> <a  href="https://sarfaa.com/" target="_blank" rel="noreferrer">SARFAA.</a></small> */}
          </div>
        </Container>
      </div>
    </div>
  );
};
export default Footer;
