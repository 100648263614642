import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as IoIcons from "react-icons/io5";
import * as AiIcons from "react-icons/ai";
import * as ImIcons from "react-icons/im";
import * as HiIcons from "react-icons/hi";
import { Link, Outlet } from "react-router-dom";
import {
  Button,
  Collapse,
  Container,
  Dropdown,
  NavDropdown,
  Badge,
} from "react-bootstrap";

import "./AdminContent.css";
import AdminFooter from "./AdminFooter";

const Test = () => {
  const [toggleNav, setToggleNav] = useState(false);

  const [clickState, setClickState] = useState(false);
  const [test, setTest] = useState(false);
  const [open, setOpen] = useState(false);
 

  const ToggleClass = (index) => {
    setClickState(index);
  };

  const logoutAdmin = () => {
    localStorage.removeItem("adminemail");
    localStorage.removeItem("LOGGED_IN_ADMIN_ID");
    localStorage.removeItem("adminName");
  };
  return (
    <div className="admin d-flex test">
      <nav className={`sidebar  ${toggleNav ? "close" : ""}`}>
        <header className="mt-3 mb-2">
          <Link to="/" target="_blank">
            <div className="mx-4 image-text d-flex align-items-center justify-content-between">
              <span className="image">
                {/* <img src={require("../../Assets/test.png")} alt="" /> */}
              </span>
              <div className="text logo-text ">
                <span className="name">Keplerage</span>
              </div>
            </div>
          </Link>

          <FaIcons.FaChevronCircleRight
            className="bx bx-chevron-right toggle"
            onClick={() => setToggleNav(!toggleNav)}
          />
        </header>

        <div className="menu-bar">
          <div className="menu d-flex flex-wrap">
            <ul className="menu-links ps-0">
              <li className="nav-link">
                <Link to="">
                  <AiIcons.AiOutlineDashboard className="icon" />
                  <span className="text nav-text">Dashboard</span>
                </Link>
              </li>

              <li className="nav-link">
                <Link to="main-categories">
                  <BsIcons.BsSliders className="icon" />
                  <span className="text nav-text">Main Categories</span>
                </Link>
              </li>
              <li className="nav-link">
                <Link to="all-categories">
                  <BiIcons.BiNews className="icon" />
                  <span className="text nav-text">All Categories</span>
                </Link>
              </li>
              <li className="nav-link">
                <Link to="posts">
                  <FaIcons.FaBookReader className="icon" />
                  <span className="text nav-text">All Posts</span>
                </Link>
              </li>
              <li className="nav-link">
                <Link to="subscriber">
                  <CgIcons.CgEventbrite className="icon" />
                  <span className="text nav-text">Subscriber</span>
                </Link>
              </li>
              <li className="nav-link">
                <Link to="" onClick={logoutAdmin}>
                  <ImIcons.ImExit className="icon" />
                  <span className="text nav-text">Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section className="rightbar">
        <Container fluid style={{ marginBottom: "100px" }}>
          <Outlet />
        </Container>
        <AdminFooter />
      </section>
    </div>
  );
};

export default Test;
