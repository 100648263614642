import React, { Fragment, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import * as TbIcons from "react-icons/tb";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from "../../Components/Navbar/Navbar";
import LoadingSpinner from "../../Components/LoadingSpinner";
import Footer from "../../Components/Footer/Footer";
import { BACKEND_BASE_URL } from "../../Components/GlobalVariables";
import moment from "moment";
import Swal from "sweetalert2";
import "./Index.css";

const Index = () => {
  //================== Fetch 1st 10 Blog ===========================

  const [firstTenBlog, setFirstTenBlog] = useState([]);
  const [recentComments, setrecentComments] = useState([]);

  useEffect(() => {
    axios
      .get("https://trodad-team.keplerage.com/api/master-get")
      .then((res) => {
        setFirstTenBlog(res.data.firstTenPost);
        setrecentComments(res.data.recent_comments);
      });
  }, []);

  //================== Fetch from 11th Blog ===========================

  // ======================Infinite scroll========================

  // const [infiniteBlogInfo, setInfiniteBlogInfo] = useState([]);
  // const [totalBlog, setTotalBlog] = useState([]);

  // const fetchBlogs = () => {
  //   console.log("infiniteBlogInfo.length", infiniteBlogInfo.length);
  //   let pageNo = Math.ceil(infiniteBlogInfo.length / 18) + 1;
  //   console.log("pageNo", pageNo);
  //   const queryParam = "?page=" + pageNo;

  //   axios
  //     .get("https://trodad-team.keplerage.com/api/infinity-post" + queryParam)
  //     .then((res) => {
  //       const apiRes = res.data.allPosts.data;
  //       setTotalBlog(res.data.allPosts.total);

  //       const mergeData = [...infiniteBlogInfo, ...apiRes];
  //       setInfiniteBlogInfo(mergeData);
  //     });
  // };
  // ======================Infinite scroll========================

  const [totalBlog, setTotalBlog] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);
  const [firstPage, setFirstPage] = useState([]);
  const [lastPage, setLastPage] = useState([]);

  const fetchBlogs = () => {
    axios
      .get(`https://trodad-team.keplerage.com/api/infinity-post`)
      .then((res) => {
        setTotalBlog(res.data?.allPosts?.data);
        setCurrentPage(res.data?.allPosts?.current_page);
        setFirstPage(res.data?.allPosts?.from);
        setLastPage(res.data?.allPosts?.last_page);
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchPaginateBlogs = (num) => {
    axios
      .get(`https://trodad-team.keplerage.com/api/infinity-post?page=${num}`)
      .then((res) => {
        setTotalBlog(res.data?.allPosts?.data);
        setCurrentPage(res.data?.allPosts?.current_page);
        // setFirstPage(res.data?.allPosts?.from);
        // setLastPage(res.data?.allPosts?.last_page);
      });
  };

  // ===========================Pagination ===================
  let active = currentPage;
  let items = [];
  for (let number = 1; number <= lastPage; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => fetchPaginateBlogs(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  var now_time = new Date();

  // ============================= form submit to backend ======================
  const email = useRef();
  const postEmail = (e) => {
    const formdata = new FormData();
    formdata.append("email", email.current.value);
    console.log(typeof email.current.value);
    if (email.current.value !== "") {
      axios
        .post(`${BACKEND_BASE_URL}/api/subscribers/store`, formdata, {
          headers: { "Content-Type": "multipart/form-data" },
        })

        .then((response) => {
          if (response.data.status === 200) {
            Swal.fire({
              icon: "success",
              text: response.data.message,
              confirmButtonColor: "#5eba86",
            });

            e.target.reset();
          }
        });
    }

    e.preventDefault();
  };

  return (
    <Fragment>
      {/* <Helmet>
        
      </Helmet> */}
      {firstTenBlog.length > 0 ? (
        <>
          <Header />
          <Container className="px-lg-2 px-xl-5">
            <Row className="py-4">
              <Col sm={12} lg={8}>
                <Row>
                  {firstTenBlog.map((data, index) => (
                    <Col md={6} className="px-3 px-md-3" key={index}>
                      <div className=" flex-column overflow-hidden">
                        <Link
                          to={`/blog-details/${data.slug}`}
                          className="card_img_wrapper"
                        >
                          <img
                            src={`https://trodad-team.keplerage.com/${data.image}`}
                            alt=""
                            className="img-fluid py-1"
                          />
                        </Link>
                        <div className="flex_between my-2 feedback">
                          {/* <div
                            id={`smile_react_${data.id}`}
                            className={`flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 smile ${
                              data?.smiles[0]?.user_id ==
                              localStorage.getItem("user_id")
                                ? "reaction_active"
                                : ""
                            }  `}
                            style={{ backgroundColor: "#F3F3F6" }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleSmile(data.id);
                            }}
                          >
                            <TbIcons.TbMoodSmile size="1.2em" />
                            &nbsp;
                            <span id={`smile_show_${data.id}`}>
                              {data.smiles_count}
                            </span>
                          </div>
                          <div
                            id={`sad_react_${data.id}`}
                            className={`flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 sad ${
                              data?.sads[0]?.user_id ==
                              localStorage.getItem("user_id")
                                ? "reaction_active"
                                : ""
                            }  `}
                            style={{ backgroundColor: "#F3F3F6" }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleSad(data.id);
                            }}
                          >
                            <TbIcons.TbMoodSad size="1.2em" />
                            &nbsp;
                            <span id={`sad_show_${data.id}`}>
                              {data.sads_count}
                            </span>
                          </div>
                          <div
                            id={`star_react_${data.id}`}
                            className={`flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 star ${
                              data?.stars[0]?.user_id ==
                              localStorage.getItem("user_id")
                                ? "reaction_active"
                                : ""
                            }  `}
                            style={{ backgroundColor: "#F3F3F6" }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleStar(data.id);
                            }}
                          >
                            <AiIcons.AiOutlineStar size="1.2em" />
                            &nbsp;
                            <span id={`star_show_${data.id}`}>
                              {data.stars_count}
                            </span>
                          </div>
                          <div
                            className="flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 comment"
                            style={{ backgroundColor: "#F3F3F6" }}
                          >
                            <FaIcons.FaRegCommentAlt size=".8em" />
                            &nbsp;
                            <span>{data.comments_count}</span>
                          </div> */}
                        </div>
                        <Link
                          to={`/blog-details/${data.slug}`}
                          className="blog_title text-decoration-none text-black"
                        >
                          <h4>{data.title}</h4>
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={4} className="flex-column d-none d-lg-block">
                <div className="join_us p-3 mb-5">
                  <h2 className="text-white fw-bold text-center">Join us!</h2>
                  <div className="flex_center bg-white rounded-3">
                    <div className="flex_center bg-primary text-white m-2 px-2 py-0 rounded-3">
                      <BiIcons.BiLike color="white" /> &nbsp;
                      <span>Like</span>
                    </div>
                    <p className="mb-0 fs12 p-1">
                      41M people like this. <Link to="/">Sign Up</Link> to see
                      what your friends like
                    </p>
                  </div>
                  <div className="flex_between my-2 media_btn w-100 ">
                    <div className="bg-white px-lg-4 py-lg-1">
                      <FaIcons.FaYoutube color="red" size="1.5em" />
                    </div>
                    <div className="bg-white px-lg-4 py-lg-1">
                      <AiIcons.AiOutlineTwitter color="#1aa0f2" size="1.5em" />
                    </div>
                    <div className="bg-white px-lg-4 py-lg-1">
                      <AiIcons.AiOutlineInstagram color="tomato" size="1.5em" />
                    </div>
                  </div>
                </div>

                <div className="stay_tuned p-3 text-center">
                  <h2 className="text-white fw-bold ">Stay tuned</h2>

                  <p
                    className="mb-0 fs16 px-3 text-white"
                    style={{ lineHeight: "26px" }}
                  >
                    Subscribe to our weekly notifications to never miss our
                    coolest articles. No spam!
                  </p>
                  <p className="" style={{ color: "rgba(0,0,0,.5)" }}>
                    Please enter your email address
                  </p>
                  <Form onSubmit={postEmail}>
                    <div className="flex_between">
                      <input
                        required
                        type="email"
                        name="email"
                        placeholder="E-mail"
                        className="form-control me-2 p-2"
                        style={{
                          backgroundColor: "hsla(0,0%,100%,.4)",
                          border: "1px solid #f3f3f6",
                          borderRadius: "8px",
                        }}
                        ref={email}
                      />
                      <Button
                        type="submit"
                        size="sm"
                        className="arrow_btn p-2 border-0 bg-white"
                        style={{ borderRadius: "8px" }}
                      >
                        <AiIcons.AiOutlineRight color="black" size="1.6em" />
                      </Button>
                    </div>
                  </Form>
                </div>

                <div className="live_chat border border-1 my-5">
                  <h3 className="text-center">Live</h3>
                  {recentComments &&
                    recentComments.map((data, index) => {
                      var commentDate = moment(data?.created_at);
                      var endDate = moment(now_time);
                      var diff = endDate.diff(commentDate);
                      const diffDuration = moment.duration(diff);
                      return (
                        <>
                          <div className="d-flex mx-3 my-2 p-2" key={index}>
                            <div className="profile_img_icon me-2">
                              <img
                                src={require("../../Assets/avatar.png")}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <p className="user_name mb-0 me-2 text-black me-2">
                              {data.user.name}
                            </p>
                            <p
                              className="mb-0 me-2"
                              style={{ color: "#a9aeba" }}
                            >
                              {diffDuration.days() > 0 ? (
                                <>{diffDuration.days()}&nbsp;days&nbsp;</>
                              ) : diffDuration.hours() > 0 ? (
                                <>{diffDuration.hours()} &nbsp;hours&nbsp; </>
                              ) : (
                                <> {diffDuration.minutes()} minutes Ago</>
                              )}

                              {/* {moment(data?.created_at).format("DD-MM-Y")} */}
                            </p>
                          </div>
                          <div className="comment_box mx-3 my-2 px-3 py-2">
                            {data.comment_desc}.
                          </div>
                        </>
                      );
                    })}
                </div>
              </Col>
            </Row>
            {/* fetch 2nd part blogs */}

            {/* ================= Infinite Scroll ===================== */}
            <Row>
              {totalBlog &&
                totalBlog?.map((data, index) => (
                  <Col md={4} className="px-3 px-md-3" key={index}>
                    <div className=" flex-column overflow-hidden">
                      <Link
                        to={`/blog-details/${data.slug}`}
                        className="card_img_wrapper"
                      >
                        <img
                          src={`https://trodad-team.keplerage.com/${data.image}`}
                          alt=""
                          className="img-fluid p-1"
                        />
                      </Link>
                      {/* <div className="flex_between my-2 feedback">
                        <div
                          id={`smile_react_${data.id}`}
                          className={`flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 smile ${
                            data?.smiles[0]?.user_id ==
                            localStorage.getItem("user_id")
                              ? "reaction_active"
                              : ""
                          }  `}
                          style={{ backgroundColor: "#F3F3F6" }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSmile(data.id);
                          }}
                        >
                          <TbIcons.TbMoodSmile size="1.2em" />
                          &nbsp;
                          <span id={`smile_show_${data.id}`}>
                            {data.smiles_count}
                          </span>
                        </div>
                        <div
                          id={`sad_react_${data.id}`}
                          className={`flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 sad ${
                            data?.sads[0]?.user_id ==
                            localStorage.getItem("user_id")
                              ? "reaction_active"
                              : ""
                          }  `}
                          style={{ backgroundColor: "#F3F3F6" }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSad(data.id);
                          }}
                        >
                          <TbIcons.TbMoodSad size="1.2em" />
                          &nbsp;
                          <span id={`sad_show_${data.id}`}>
                            {data.sads_count}
                          </span>
                        </div>
                        <div
                          id={`star_react_${data.id}`}
                          className={`flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 star ${
                            data?.stars[0]?.user_id ==
                            localStorage.getItem("user_id")
                              ? "reaction_active"
                              : ""
                          }  `}
                          style={{ backgroundColor: "#F3F3F6" }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleStar(data.id);
                          }}
                        >
                          <AiIcons.AiOutlineStar size="1.2em" />
                          &nbsp;
                          <span id={`star_show_${data.id}`}>
                            {data.stars_count}
                          </span>
                        </div>
                        <div
                          className="flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 comment"
                          style={{ backgroundColor: "#F3F3F6" }}
                        >
                          <FaIcons.FaRegCommentAlt size=".8em" />
                          &nbsp;
                          <span>{data.comments_count}</span>
                        </div>
                      </div> */}
                      <Link
                        to={`/blog-details/${data.slug}`}
                        className="blog_title text-decoration-none text-black"
                      >
                        <h4>{data.title}</h4>
                      </Link>
                    </div>
                  </Col>
                ))}
            </Row>
            {/* ================= Infinite Scroll ===================== */}
            <div className="custom_pagination d-flex justify-content-center flex-wrap">
              {firstPage !== null && (
                <div className="mt-5 flex_center">
                  <Pagination>
                    {currentPage != firstPage && <Pagination.Prev />}
                    {items} {currentPage != lastPage && <Pagination.Next />}
                  </Pagination>
                </div>
              )}
            </div>
          </Container>
          <Footer />
        </>
      ) : (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      )}
    </Fragment>
  );
};

export default Index;
