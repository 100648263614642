import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";
import * as BiIcons from "react-icons/bi";
import * as FaIcons from "react-icons/fa";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../Components/GlobalVariables";
import Swal from "sweetalert2";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";

const Subscriber = () => {
  //=================================== Fetch Table Data ===================================

  const [tableData, setTableData] = useState([]);

  const fetchEmail = async () => {
    await axios.get(`${BACKEND_BASE_URL}/api/admin/subscribers`).then((res) => {
      setTableData(res.data.subscribers);
    });
  };

  useEffect(() => {
    fetchEmail();
  }, []);

  // =============================== Delete Data ===============================
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(`${BACKEND_BASE_URL}/api/admin/banners-image/delete/${id}`)
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchEmail();
        });
    }
  };

  return (
    <div className="main__container">
      <div className="content-wrapper">
        <div className="breadcrumb">
          <div className="breadcrumb-item">
            <Link to="/admin">Dashboard</Link>
          </div>
        </div>

        <div className="col-md-12 mt-3">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <span className="top-border"></span>

                <Table responsive>
                  <thead className="text-center">
                    <tr>
                      <th>#</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {tableData?.map((data, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{data?.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscriber;
