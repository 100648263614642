import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Index from "./Pages/Index/Index";
import BlogDetails from "./Pages/BlogDetails/BlogDetails";
import PageChangeToTop from "./Utils/PageChangeToTop";
import CategorizedBlogs from "./Pages/Categorized Blog/CategorizedBlogs";
import "./App.css";
import Admin from "./Pages/Admin/Admin";
import AdminDashboard from "./Pages/Admin/Dashboard/AdminDashboard";

import AllPosts from "./Pages/Admin/AllPosts";
import Subscriber from "./Pages/Admin/Subscriber";
import MainCategories from "./Pages/Admin/MainCategories";
import AllCategories from "./Pages/Admin/AllCategories";
import AdminPrivateRoute from "./Components/PrivateRoute/AdminPrivateRoute";
import AdminLogin from "./Pages/Admin/AdminLogin";
import UserSignUp from "./Pages/UserSignUp & SignIn/UserSignUp";
import UserLogin from "./Pages/UserSignUp & SignIn/UserLogin";
import UserProfile from "./Pages/User-Profile/UserProfile";

function App() {
  return (
    <>
      <Router>
        <PageChangeToTop />
        <Routes>
          <Route path="/" element={<Index />} />

          
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/blog-details/:slug" element={<BlogDetails />} />
          <Route
            path="/category/:categorySlug"
            element={<CategorizedBlogs />}
          />

          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/user/login" element={<UserLogin />} />
          <Route path="/user/signup" element={<UserSignUp />} />

          <Route
            path="admin"
            element={
              <AdminPrivateRoute>
                <Admin />
              </AdminPrivateRoute>
            }
          >
            <Route index element={<AdminDashboard />} />
            <Route path="main-categories" element={<MainCategories />} />
            <Route path="all-categories" element={<AllCategories />} />
            <Route path="posts" element={<AllPosts />} />
            {/* <Route path="tags" element={<Tags />} /> */}
            <Route path="subscriber" element={<Subscriber />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
