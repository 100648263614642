import React, { Fragment, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import * as FiIcons from "react-icons/fi";
import * as TbIcons from "react-icons/tb";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from "../../Components/Navbar/Navbar";
import LoadingSpinner from "./UserProfile.js";
import Footer from "../../Components/Footer/Footer";
import { BACKEND_BASE_URL } from "../../Components/GlobalVariables";
import Swal from "sweetalert2";
import "./user-profile.css";

const UserProfile = () => {
  // ======================Infinite scroll========================

  const [totalBlog, setTotalBlog] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);
  const [firstPage, setFirstPage] = useState([]);
  const [lastPage, setLastPage] = useState([]);

  const fetchBlogs = () => {
    axios
      .get(`https://trodad-team.keplerage.com/api/infinity-post`)
      .then((res) => {
        setTotalBlog(res.data?.allPosts?.data);
        setCurrentPage(res.data?.allPosts?.current_page);
        setFirstPage(res.data?.allPosts?.from);
        setLastPage(res.data?.allPosts?.last_page);
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchPaginateBlogs = (num) => {
    axios
      .get(`https://trodad-team.keplerage.com/api/infinity-post?page=${num}`)
      .then((res) => {
        setTotalBlog(res.data?.allPosts?.data);
        setCurrentPage(res.data?.allPosts?.current_page);
        // setFirstPage(res.data?.allPosts?.from);
        // setLastPage(res.data?.allPosts?.last_page);
      });
  };

  // ===========================Pagination ===================
  let active = currentPage;
  let items = [];
  for (let number = 1; number <= lastPage; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => fetchPaginateBlogs(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  // ============================= form submit to backend ======================
  const email = useRef();
  const postEmail = (e) => {
    const formdata = new FormData();
    formdata.append("email", email.current.value);
    console.log(typeof email.current.value);
    if (email.current.value !== "") {
      axios
        .post(`${BACKEND_BASE_URL}/api/subscribers/store`, formdata, {
          headers: { "Content-Type": "multipart/form-data" },
        })

        .then((response) => {
          if (response.data.status === 200) {
            Swal.fire({
              icon: "success",
              text: response.data.message,
              confirmButtonColor: "#5eba86",
            });

            e.target.reset();
          }
        });
    }

    e.preventDefault();
  };

  const tabItem = (tabId) => {
    const id = document.getElementById(tabId);
    // const tab_class = document.getElementsByClassName("tab-item");
    // if (tab_class.classList.contains("tab_active")) {
    document.getElementById("tab-item_1").classList.remove("tab_active");
    document.getElementById("tab-item_2").classList.remove("tab_active");
    document.getElementById("tab-item_3").classList.remove("tab_active");
    // }
    if (id.classList.contains("tab_active")) {
      id.classList.remove("tab_active");
    } else id.classList.add("tab_active");
  };

  return (
    <Fragment>
      {/* {firstTenBlog.length > 0 ? ( */}
      <>
        <Header />
        <Container className="px-lg-2 px-xl-5">
          <Row className="py-4">
            <Col sm={12} lg={8}>
              <div className="flex_between my-3">
                <div className="d-flex align-items-center">
                  <div className="profile_img me-3">
                    <img
                      src={require("../../Assets/avatar.png")}
                      alt=""
                      className=""
                      width={80}
                      height={80}
                    />
                  </div>
                  <div className="profile_name fw-bold text-capitalize fs25">
                    {localStorage.getItem("name")}
                  </div>
                </div>
                <div className="flex_center setting cursor_pointer">
                  <p className="mb-0 me-3">Edit Settings</p>
                  <FiIcons.FiSettings className="setting_icon" size="1.2rem" />
                </div>
              </div>
              <div className="d-flex  border-bottom tab_section">
                <div
                  id="tab-item_1"
                  className="flex_center_start me-4 tab-item"
                  onClick={() => tabItem("tab-item_1")}
                >
                  <AiIcons.AiOutlineStar size="1rem" className="me-2" />
                  <span className="fs16 ">Favourite</span>
                </div>
                <div
                  id="tab-item_2"
                  className="flex_center_start me-4 tab-item"
                  onClick={() => tabItem("tab-item_2")}
                >
                  <FaIcons.FaRegCommentAlt size="1rem" className="me-2" />
                  <span className="fs16">Comments</span>
                </div>
                <div
                  id="tab-item_3"
                  className="flex_center_start me-4 tab-item"
                  onClick={() => tabItem("tab-item_3")}
                >
                  <TbIcons.TbMoodSmile size="1rem" className="me-2" />
                  <span className="fs16 ">Likes</span>
                </div>
              </div>
            </Col>
            <Col lg={4} className="flex-column d-none d-lg-block">
              <div className="join_us p-3 mb-5">
                <h2 className="text-white fw-bold text-center">Join us!</h2>
                <div className="flex_center bg-white rounded-3">
                  <div className="flex_center bg-primary text-white m-2 px-2 py-0 rounded-3">
                    <BiIcons.BiLike color="white" /> &nbsp;
                    <span>Like</span>
                  </div>
                  <p className="mb-0 fs12 p-1">
                    41M people like this. <Link to="/">Sign Up</Link> to see
                    what your friends like
                  </p>
                </div>
                <div className="flex_between my-2 media_btn w-100 ">
                  <div className="bg-white px-lg-4 py-lg-1">
                    <FaIcons.FaYoutube color="red" size="1.5em" />
                  </div>
                  <div className="bg-white px-lg-4 py-lg-1">
                    <AiIcons.AiOutlineTwitter color="#1aa0f2" size="1.5em" />
                  </div>
                  <div className="bg-white px-lg-4 py-lg-1">
                    <AiIcons.AiOutlineInstagram color="tomato" size="1.5em" />
                  </div>
                </div>
              </div>

              <div className="stay_tuned p-3 text-center">
                <h2 className="text-white fw-bold ">Stay tuned</h2>

                <p
                  className="mb-0 fs16 px-3 text-white"
                  style={{ lineHeight: "26px" }}
                >
                  Subscribe to our weekly notifications to never miss our
                  coolest articles. No spam!
                </p>
                <p className="" style={{ color: "rgba(0,0,0,.5)" }}>
                  Please enter your email address
                </p>
                <Form onSubmit={postEmail}>
                  <div className="flex_between">
                    <input
                      required
                      type="email"
                      name="email"
                      placeholder="E-mail"
                      className="form-control me-2 p-2"
                      style={{
                        backgroundColor: "hsla(0,0%,100%,.4)",
                        border: "1px solid #f3f3f6",
                        borderRadius: "8px",
                      }}
                      ref={email}
                    />
                    <Button
                      type="submit"
                      size="sm"
                      className="arrow_btn p-2 border-0 bg-white"
                      style={{ borderRadius: "8px" }}
                    >
                      <AiIcons.AiOutlineRight color="black" size="1.6em" />
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
          {/* fetch 2nd part blogs */}

          {/* ================= Infinite Scroll ===================== */}
          <Row>
            {totalBlog &&
              totalBlog?.map((data, index) => (
                <Col md={4} className="px-3 px-md-3" key={index}>
                  <div className=" flex-column overflow-hidden">
                    <Link
                      to={`/blog-details/${data.slug}`}
                      className="card_img_wrapper"
                    >
                      <img
                        src={`https://trodad-team.keplerage.com/${data.image}`}
                        alt=""
                        className="img-fluid p-1"
                      />
                    </Link>
                    <Link
                      to={`/blog-details/${data.slug}`}
                      className="blog_title text-decoration-none text-black"
                    >
                      <h4>{data.title}</h4>
                    </Link>
                  </div>
                </Col>
              ))}
          </Row>
          {/* ================= Infinite Scroll ===================== */}
          <div className="custom_pagination d-flex justify-content-center flex-wrap">
            {firstPage !== null && (
              <div className="mt-5 flex_center">
                <Pagination>
                  {currentPage != firstPage && <Pagination.Prev />}
                  {items} {currentPage != lastPage && <Pagination.Next />}
                </Pagination>
              </div>
            )}
          </div>
        </Container>
        <Footer />
      </>
      {/* ) : (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      )
       }  */}
    </Fragment>
  );
};

export default UserProfile;
