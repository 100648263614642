import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Pagination, Spinner } from "react-bootstrap";
import * as TbIcons from "react-icons/tb";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../Components/GlobalVariables";
import axios from "axios";
import "./CategorizedBlogs.css";
import Header from "../../Components/Navbar/Navbar";

const CategorizedBlogs = () => {
  const { categorySlug } = useParams();
  const [categorizedBlogInfo, setCategorizedBlogInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);
  const [firstPage, setFirstPage] = useState([]);
  const [lastPage, setLastPage] = useState([]);

  //================== Fetch Categorized blogs ===========================

  useEffect(() => {
    axios
      .get(`${BACKEND_BASE_URL}/api/category/${categorySlug}`)
      .then((res) => {
        setCurrentPage(res.data.all__category__posts.current_page);
        setFirstPage(res.data.all__category__posts.from);
        setLastPage(res.data.all__category__posts.last_page);
        setCategorizedBlogInfo(res.data.all__category__posts.data);
      });
  }, [categorySlug]);

  const PaginateData = (num) => {
    axios
      .get(`${BACKEND_BASE_URL}/api/category/${categorySlug}?page=${num}`)
      .then((res) => {
        setCategorizedBlogInfo(res.data.all__category__posts.data);
      });
  };

  // ===========================Pagination ===================
  let active = currentPage;
  let items = [];
  for (let number = 1; number <= lastPage; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === active}
        onClick={() => PaginateData(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Fragment>
      <Header />
      <Container className="px-lg-2 px-xl-5">
        {/* ================= Infinite Scroll ===================== */}
        <Row className="my-5">
          {categorizedBlogInfo.length > 0 ? (
            categorizedBlogInfo.map((data, index) => (
              <Col md={4} className="px-3 px-md-3 mb-5" key={index}>
                <div className=" flex-column overflow-hidden">
                  <Link
                    to={`/blog-details/${data.slug}`}
                    className="card_img_wrapper"
                  >
                    <img
                      src={`https://trodad-team.keplerage.com/${data.image}`}
                      alt=""
                      className="img-fluid py-1"
                    />
                  </Link>
                  <div className="flex_between my-2 feedback">
                    <div
                      className="flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 smile"
                      style={{ backgroundColor: "#F3F3F6" }}
                    >
                      <TbIcons.TbMoodSmile size="1.2em" />
                      &nbsp;
                      <span>{data.smiles_count}</span>
                    </div>
                    <div
                      className="flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 sad"
                      style={{ backgroundColor: "#F3F3F6" }}
                    >
                      <TbIcons.TbMoodSad size="1.2em" />
                      &nbsp;
                      <span>{data.sads_count}</span>
                    </div>
                    <div
                      className="flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 star"
                      style={{ backgroundColor: "#F3F3F6" }}
                    >
                      <AiIcons.AiOutlineStar size="1.2em" />
                      &nbsp;
                      <span>{data.stars_count}</span>
                    </div>
                    <div
                      className="flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 comment"
                      style={{ backgroundColor: "#F3F3F6" }}
                    >
                      <FaIcons.FaRegCommentAlt size=".8em" />
                      &nbsp;
                      <span>{data.comments_count}</span>
                    </div>
                  </div>
                  <Link
                    to={`/blog-details/${data.slug}`}
                    className="blog_title text-decoration-none text-black"
                  >
                    <h4>{data.title}</h4>
                  </Link>
                </div>
              </Col>
            ))
          ) : (
            <>
              <div className="flex_center loading_spinner">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden ">Loading...</span>
                </Spinner>
              </div>
            </>
          )}

          {lastPage !== firstPage && firstPage !== null && (
            <div className="mt-5 flex_center">
              <Pagination>{items}</Pagination>
            </div>
          )}
       
        </Row>
        {/* ================= Infinite Scroll ===================== */}
      </Container>
    </Fragment>
  );
};

export default CategorizedBlogs;
