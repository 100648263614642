import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import * as TbIcons from "react-icons/tb";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import Parse from "html-react-parser";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { BACKEND_BASE_URL } from "../../Components/GlobalVariables";
import moment from "moment";
import axios from "axios";
import "./BlogDetails.css";
import Header from "../../Components/Navbar/Navbar";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../Components/LoadingSpinner";
import Swal from "sweetalert2";

const BlogDetails = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  //================== Fetch blog details from Api ===========================
  const [blogInfo, setblogInfo] = useState([]);
  const [relatedBlogInfo, setRelatedBlogInfo] = useState([]);
  const [blogComments, setblogComments] = useState([]);

  const fetchBlogInfo = () => {
    axios
      .get(`https://trodad-team.keplerage.com/api/posts/${slug}`)
      .then((res) => {
        setblogInfo(res.data.post_info);
        setblogComments(res.data.post_comments);
        setRelatedBlogInfo(res.data.related_post);
      });
  };
  useEffect(() => {
    fetchBlogInfo();
  }, []);

  //============================= Smile ===============================
  const handleSmile = (post_id) => {
    if (
      localStorage.getItem("name") &&
      localStorage.getItem("email") &&
      localStorage.getItem("user_type") == 0
    ) {
      axios
        .get(
          `${BACKEND_BASE_URL}/api/smile/${post_id}/${localStorage.getItem(
            "user_id"
          )}`
        )
        .then((response) => {
          if (response.data.status === 200) {
            const spanId = "smile_show_" + post_id;
            const divId = "smile_react_" + post_id;
            const sadSpanId = "sad_show_" + post_id;
            const sadDivId = "sad_react_" + post_id;
            document.getElementById(spanId).innerHTML =
              response.data?.smile_count;
            if (response.data?.smile_add == 1) {
              if (response.data?.sad_add == 0) {
                document
                  .getElementById(sadDivId)
                  .classList.remove("reaction_active");
                document.getElementById(sadSpanId).innerHTML =
                  response.data?.sad_count;
              }
              document.getElementById(divId).classList.add("reaction_active");
            } else {
              document
                .getElementById(divId)
                .classList.remove("reaction_active");
            }
          }
        });
    } else {
      navigate("/user/login", { replace: true });
    }
  };

  //============================= Sad ===============================
  const handleSad = (post_id) => {
    if (
      localStorage.getItem("name") &&
      localStorage.getItem("email") &&
      localStorage.getItem("user_type") == 0
    ) {
      axios
        .get(
          `${BACKEND_BASE_URL}/api/sad/${post_id}/${localStorage.getItem(
            "user_id"
          )}`
        )
        .then((response) => {
          if (response.data.status === 200) {
            const spanId = "sad_show_" + post_id;
            const divId = "sad_react_" + post_id;
            const smileSpanId = "smile_show_" + post_id;
            const smileDivId = "smile_react_" + post_id;
            document.getElementById(spanId).innerHTML =
              response.data?.sad_count;
            if (response.data?.sad_add == 1) {
              if (response.data?.smile_add == 0) {
                document
                  .getElementById(smileDivId)
                  .classList.remove("reaction_active");
                document.getElementById(smileSpanId).innerHTML =
                  response.data?.smile_count;
              }
              document.getElementById(divId).classList.add("reaction_active");
            } else {
              document
                .getElementById(divId)
                .classList.remove("reaction_active");
            }
          }
        });
    } else {
      navigate("/user/login", { replace: true });
    }
  };

  //============================= Star ===============================
  const handleStar = (post_id) => {
    if (
      localStorage.getItem("name") &&
      localStorage.getItem("email") &&
      localStorage.getItem("user_type") == 0
    ) {
      axios
        .get(
          `${BACKEND_BASE_URL}/api/star/${post_id}/${localStorage.getItem(
            "user_id"
          )}`
        )
        .then((response) => {
          if (response.data.status === 200) {
            const spanId = "star_show_" + post_id;
            const divId = "star_react_" + post_id;

            document.getElementById(spanId).innerHTML =
              response.data?.star_count;
            if (response.data?.star_add == 1) {
              document.getElementById(divId).classList.add("reaction_active");
            } else {
              document
                .getElementById(divId)
                .classList.remove("reaction_active");
            }
          }
        });
    } else {
      navigate("/user/login", { replace: true });
    }
  };

  // ============================= Comment ===========================
  const [commentId, setCommentId] = useState("");
  console.log("commentId", commentId);
  const comment = useRef();

  const postComment = (e) => {
    const formdata = new FormData();
    formdata.append("comment_desc", comment.current.value);
    formdata.append("post_id", blogInfo.id);
    formdata.append("user_id", localStorage.getItem("user_id"));
    formdata.append("comment_id", commentId);

    axios
      .post(`${BACKEND_BASE_URL}/api/comment/store`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchBlogInfo();
        }
      });
    e.preventDefault();
  };

  return (
    <Fragment>
      <Helmet>
        <title>{blogInfo?.title}</title>
        <meta name="og:description" content="mret asd" />
      </Helmet>

      <Header />
      <Container className="px-lg-2 px-xl-5">
        {blogInfo.title != null ? (
          <Row className="py-5">
            <Col sm={12} lg={8}>
              <Row>
                <Col md={12} className="px-3 px-md-3">
                  <img
                    src={`${BACKEND_BASE_URL}/${blogInfo?.image}`}
                    alt=""
                    className="img-fluid w-100 pe-2 mb-3"
                  />
                  <h1 className="text-center text-lg-start">
                    {blogInfo?.title}
                  </h1>
                  <h5 className="text-muted">{blogInfo?.category?.name}</h5>
                  <div className="flex_between my-4 feedback_blog">
                    <div
                      id={`smile_react_${blogInfo.id}`}
                      className={`flex_center px-4 py-2  px-md-5 py-md-2  rounded-3 smile ${blogInfo?.smiles?.map(
                        (d, i) =>
                          d.user_id == localStorage.getItem("user_id")
                            ? "reaction_active"
                            : ""
                      )}`}
                      style={{ backgroundColor: "#F3F3F6" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSmile(blogInfo.id);
                      }}
                    >
                      <TbIcons.TbMoodSmile size="1.2em" />
                      &nbsp;
                      <span id={`smile_show_${blogInfo.id}`}>
                        {blogInfo?.smiles_count}
                      </span>
                    </div>
                    <div
                      id={`sad_react_${blogInfo.id}`}
                      className={`flex_center px-4 py-2 px-md-5 py-md-2  rounded-3 sad ${blogInfo?.sads?.map(
                        (d, i) =>
                          d.user_id == localStorage.getItem("user_id")
                            ? "reaction_active"
                            : ""
                      )}`}
                      style={{ backgroundColor: "#F3F3F6" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSad(blogInfo.id);
                      }}
                    >
                      <TbIcons.TbMoodSad size="1.2em" />
                      &nbsp;
                      <span id={`sad_show_${blogInfo.id}`}>
                        {blogInfo?.sads_count}
                      </span>
                    </div>
                    <div
                      id={`star_react_${blogInfo.id}`}
                      className={`flex_center px-4 py-2 px-md-5 py-md-2 rounded-3 star ${blogInfo?.stars.map(
                        (d, i) =>
                          d.user_id == localStorage.getItem("user_id")
                            ? "reaction_active"
                            : ""
                      )}`}
                      style={{ backgroundColor: "#F3F3F6" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleStar(blogInfo.id);
                      }}
                    >
                      <AiIcons.AiOutlineStar size="1.2em" />
                      &nbsp;
                      <span id={`star_show_${blogInfo.id}`}>
                        {blogInfo?.stars_count}
                      </span>
                    </div>
                    <div
                      className="flex_center px-4 py-2  px-md-5 py-md-2 rounded-3 comment"
                      style={{ backgroundColor: "#F3F3F6" }}
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById("comments").scrollIntoView();
                      }}
                    >
                      <FaIcons.FaRegCommentAlt size=".8em" />
                      &nbsp;
                      <span>{blogInfo?.comments_count}</span>
                    </div>{" "}
                  </div>
                  {/* <div className="flex_between mb-4 d-none d-lg-flex">
                  <Button
                    className="flex_center text-white px-md-3 py-md-2 rounded-3 smile"
                    style={{ backgroundColor: "#1875f2" }}
                  >
                    <FaIcons.FaFacebookF size="1.5em" />
                    &nbsp;
                    <span>Share on Facebook</span>
                  </Button>
                  <Button className="flex_center text-white px-md-3 py-md-2 border-0 rounded-3 smile">
                    <BsIcons.BsMessenger size="1.5em" />
                    &nbsp;
                    <span>Share on Facebook</span>
                  </Button>

                  <Button
                    className="flex_center text-white px-md-3 py-md-2 border-0 rounded-3 smile"
                    style={{
                      backgroundColor: "#c10013",
                    }}
                  >
                    <FaIcons.FaPinterestP size="1.5em" />
                    &nbsp;
                    <span>Share on Pinterest</span>
                  </Button>
                </div> */}
                  <div className="w-100 py-2">
                    <div className="flex_between">
                      <Link
                        to="/"
                        className="text-decoration-none"
                        style={{ color: "#a9aeba" }}
                      >
                        {blogInfo?.user?.name}
                      </Link>
                      <div className="social_media_icon flex_between my-2">
                        <Button>
                          <FaIcons.FaFacebookF size="1.3em" />
                        </Button>
                        <Button>
                          <BsIcons.BsWhatsapp size="1.3em" />
                        </Button>
                        <Button>
                          <FaIcons.FaPinterestP size="1.3em" />
                        </Button>
                        <Button>
                          <BsIcons.BsMessenger size="1.3em" />
                        </Button>
                        <Button>
                          <BsIcons.BsLink45Deg size="1.3em" />
                        </Button>
                      </div>
                    </div>

                    {Parse("" + blogInfo?.description)}
                  </div>
                  <div id="comments"></div>
                  {/* Comment section */}
                  {blogComments &&
                    blogComments?.map(
                      (data, i) =>
                        data.is_replay == 0 && (
                          <Row
                            className="mx-3 my-3 p-2"
                            style={{ backgroundColor: " #f9f9f9" }}
                          >
                            <Row className="" key={i}>
                              <Col sm={12} className="mb-2">
                                <div className="d-flex align-items-center">
                                  <div className="me-1">
                                    <img
                                      src={require("../../Assets/avatar.png")}
                                      alt=""
                                      className="pe-1"
                                      width={40}
                                    />
                                  </div>
                                  <div>
                                    <div className="d-flex align-items-center comment_author_name">
                                      <h6 className="fs22 fw-bold me-3 mb-0">
                                        {data?.user.name}
                                      </h6>
                                      <span>
                                        {moment(data?.created_at).format(
                                          "DD-MM-Y"
                                        )}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} className=" mb-2">
                                <div className="comment_content ps-4">
                                  <p className="my-2">{data?.comment_desc}</p>
                                  <button
                                    onClick={() => {
                                      setCommentId(data?.id);
                                      document
                                        .getElementById("comment")
                                        .focus();
                                    }}
                                    type="button"
                                    className="px-3 py-1 border-0  rounded-3 bg-primary bg-opacity-75 text-white reply_btn"
                                  >
                                    Reply
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            {blogComments &&
                              blogComments?.map(
                                (d, i) =>
                                  d.is_replay == 1 &&
                                  data.id == d.comment_id && (
                                    <Row className="my-3">
                                      <div
                                        className="col-sm-10 ms-3 ms-md-5"
                                        style={{
                                          backgroundColor: "#ededed",
                                          borderRadius: "6px",
                                        }}
                                      >
                                        <Col sm={12}>
                                          <div className="d-flex align-items-center p-2">
                                            <div className="me-1">
                                              <img
                                                src={require("../../Assets/avatar.png")}
                                                alt=""
                                                className="pe-1"
                                                width={40}
                                              />
                                            </div>
                                            <div>
                                              <div className="d-flex align-items-center comment_author_name">
                                                <h6 className="fs22 fw-bold me-3 mb-0">
                                                  {d?.user.name}
                                                </h6>
                                                <span>
                                                  {moment(d?.created_at).format(
                                                    "DD-MM-Y"
                                                  )}{" "}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </Col>

                                        <Col sm={12}>
                                          <div className=" comment_author_name mt-3 ms-2"></div>
                                          <p className="mt-2 ms-2 ">
                                            {d?.comment_desc}
                                          </p>
                                        </Col>
                                      </div>
                                    </Row>
                                  )
                              )}
                          </Row>
                        )
                    )}
                  {/* Post Comment Section */}
                  {localStorage.getItem("user_id") && (
                    <div className="post_comment">
                      <h3 className="mb-3">Write your comment</h3>
                      <Form onSubmit={postComment}>
                        <Form.Control
                          as="textarea"
                          id="comment"
                          rows={5}
                          placeholder="Write Your Comment"
                          style={{
                            boxShadow: "1px 3px 1px #9E9E9E",
                            borderColor: "#9E9E9E",
                          }}
                          ref={comment}
                        />
                        <Button type="submit" className="w-50 mt-5 comment_btn">
                          Send
                        </Button>
                      </Form>
                    </div>
                  )}

                  <div className="custom__social d-none d-lg-block">
                    <Link
                      target="_blank"
                      to="https://www.facebook.com/sharer/sharer.php?u=https://www.keplerage.com/post/what-is-web-hosting-top-5-web-hostings-in-the-world-2021"
                      className="facebook p-2"
                    >
                      <FaIcons.FaFacebookF size="1.5em" />
                    </Link>

                    <Link
                      target="_blank"
                      to="https://twitter.com/intent/tweet?text=What+is+web+hosting%3F+Top+5+web+hosting%E2%80%99s+in+the+world+2021&amp;url=https://www.keplerage.com/post/what-is-web-hosting-top-5-web-hostings-in-the-world-2021"
                      className="twitter p-2 "
                    >
                      <FaIcons.FaTwitter size="1.5em" />
                    </Link>

                    <Link
                      target="_blank"
                      to="https://twitter.com/intent/tweet?text=What+is+web+hosting%3F+Top+5+web+hosting%E2%80%99s+in+the+world+2021&amp;url=https://www.keplerage.com/post/what-is-web-hosting-top-5-web-hostings-in-the-world-2021"
                      className="whatsapp p-2 "
                    >
                      <BsIcons.BsWhatsapp size="1.5em" />
                    </Link>
                    <Link
                      target="_blank"
                      to="https://pinterest.com/pin/create/button/?url=https://www.keplerage.com/post/what-is-web-hosting-top-5-web-hostings-in-the-world-2021"
                      className="pinterest p-2"
                    >
                      <FaIcons.FaPinterestP size="1.5em" />
                    </Link>
                    <Link
                      target="_blank"
                      to="https://telegram.me/share/url?url=https://www.keplerage.com/post/what-is-web-hosting-top-5-web-hostings-in-the-world-2021&amp;text=What+is+web+hosting%3F+Top+5+web+hosting%E2%80%99s+in+the+world+2021"
                      className="telegram p-2"
                    >
                      <FaIcons.FaTelegramPlane size="1.5em" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg={4} className="flex-column d-none d-lg-block">
              {relatedBlogInfo?.map((data, i) => (
                <div className=" flex-column overflow-hidden mb-5" key={i}>
                  <Link
                    to={`/blog-details/${data.slug}`}
                    className="card_img_wrapper"
                  >
                    <img
                      src={`${BACKEND_BASE_URL}/${data.image}`}
                      alt=""
                      className="img-fluid py-1"
                    />
                  </Link>
                  <div className="flex_between my-2 feedback">
                    <div
                      className="flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 smile"
                      style={{ backgroundColor: "#F3F3F6" }}
                    >
                      <TbIcons.TbMoodSmile size="1.2em" />
                      &nbsp;
                      <span>{data.smiles_count}</span>
                    </div>
                    <div
                      className="flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 sad"
                      style={{ backgroundColor: "#F3F3F6" }}
                    >
                      <TbIcons.TbMoodSad size="1.2em" />
                      &nbsp;
                      <span>{data.sads_count}</span>
                    </div>
                    <div
                      className="flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 star"
                      style={{ backgroundColor: "#F3F3F6" }}
                    >
                      <AiIcons.AiOutlineStar size="1.2em" />
                      &nbsp;
                      <span>{data.stars_count}</span>
                    </div>
                    <div
                      className="flex_center  px-md-3 py-md-1 px-lg-2 px-xl-3 py-xl-2 rounded-3 comment"
                      style={{ backgroundColor: "#F3F3F6" }}
                    >
                      <FaIcons.FaRegCommentAlt size=".8em" />
                      &nbsp;
                      <span>{data.comments_count}</span>
                    </div>
                  </div>
                  <Link
                    to={`/blog-details/${data.slug}`}
                    className="blog_title text-decoration-none text-black"
                  >
                    <h4>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, mollitia.
                    </h4>
                  </Link>
                </div>
              ))}
            </Col>
          </Row>
        ) : (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <LoadingSpinner />
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default BlogDetails;
