import React, { useEffect, useRef, useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  Offcanvas,
  Row,
  Col,
  NavItem,
  Form,
  Button,
  Modal,
  NavLink,
  Dropdown,
  NavDropdown,
} from "react-bootstrap";
import profileLogo from "../../Assets/user-unauthorized.png";

import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io5";
import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";
import * as HiIcon from "react-icons/hi";
import * as CgIcon from "react-icons/cg";
import { Link } from "react-router-dom";
import { BACKEND_BASE_URL } from "../GlobalVariables";
import "./Navbar.css";
import axios from "axios";

const Header = () => {
  const [clickState, setClickState] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [navItem, setNavItem] = useState([]);

  const [randomMainCategoryPost, setRandomMainCategoryPost] = useState([]);

  const ToggleClass = (index) => {
    setClickState(index);
  };

  const fetchBlogs = () => {
    axios
      .get("https://trodad-team.keplerage.com/api/master-get")
      .then((res) => {
        setNavItem(res.data.menu);
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  // ==================== Toggle Control ========================

  const [signInFormDisplay, setSignInFormDisplay] = useState(true);
  const [signUpFormDisplay, setSignUpFormDisplay] = useState(false);

  const SignUpControl = (e) => {
    e.preventDefault();
    setSignInFormDisplay(true);
    setSignUpFormDisplay(false);
  };

  const SignInControl = (e) => {
    e.preventDefault();
    setSignUpFormDisplay(true);
    setSignInFormDisplay(false);
  };

  const MainCategoryPost = (id) => {
    axios
      .get(`${BACKEND_BASE_URL}/api/parrent-category/random-post/${id}`)
      .then((res) => {
        setRandomMainCategoryPost(res.data.random__main__category__posts);
      });
  };

  const CategoryPost = (id) => {
    axios
      .get(`${BACKEND_BASE_URL}/api/category/random-post/${id}`)
      .then((res) => {
        setRandomMainCategoryPost(res.data.random__category__posts);
      });
  };

  // ===============================User login=============================

  const Email = useRef();
  const Password = useRef();

  const [message, setMessage] = useState();
  const [userEmailError, setUserEmailError] = useState("");
  const [userPassError, setUserPassError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("email", Email.current.value);
    formdata.append("password", Password.current.value);

    axios
      .post(`${BACKEND_BASE_URL}/api/user/login/process`, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        if (response.data.status == 400) {
          if (response.data.errors) {
            const { email, password } = response.data.errors;
            setUserEmailError(email);
            setUserPassError(password);
          }
        }
        const { message } = response.data;
        setMessage(message);

        if (response.data.status === 1) {
          localStorage.setItem("email", response.data?.loggedInUser?.email);
          localStorage.setItem(
            "LOGGED_IN_USER_ID",
            response.data?.loggedInUser?.id
          );
          setModalShow(false);
        }
      });
  };

  return (
    <>
      {/* =============================== Desktop Header ============================ */}
      <div className="desktop_header">
        <Navbar className="py-0 position-relative">
          <Container className="px-0 px-lg-2 px-xl-5">
            <Navbar.Brand href="/">
              <div className="site_logo">
                <img
                  src={require("../../Assets/kepler-logo.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Navbar.Brand>
            <Nav className="desktop_navbar">
              <Nav.Link
                as={Link}
                to="/"
                onClick={() => ToggleClass(2)}
                className={`flex_center py-4  border border-white ${
                  clickState === 2 ? "active" : ""
                }`}
              >
                <FaIcons.FaRegLightbulb size=".8rem" />
                <span className="ms-1 px-1">Popular</span>
              </Nav.Link>
              {navItem.map((data, i) => (
                <Nav.Link
                  as="li"
                  className="dropdown_wrapper flex_center py-4 border border-white"
                  key={i}
                >
                  <span onMouseOver={() => MainCategoryPost(data.id)}>
                    {data.name}
                  </span>
                  <div className="dropdown_section p-0">
                    <Row className="container px-5 py-4 m-auto">
                      <Col md={2} className="left_col">
                        {data.category?.map((subcategory, j) => (
                          <Link
                            to={`/category/${subcategory.slug}`}
                            className="py-2 "
                            key={j}
                          >
                            <span
                              onMouseOver={() => CategoryPost(subcategory.id)}
                            >
                              {subcategory?.name}
                            </span>
                          </Link>
                        ))}
                      </Col>
                      <Col md={10}>
                        <Row className="p-0">
                          {randomMainCategoryPost.length > 0 &&
                            randomMainCategoryPost?.map((data, i) => (
                              <Col md={6} key={i}>
                                <Link
                                  to={`/blog-details/${data.slug}`}
                                  className="text-decoration-none"
                                >
                                  <div className="d-flex rightside_content mb-4  ">
                                    <div className="img_wrapper me-3 ">
                                      <img
                                        src={`${BACKEND_BASE_URL}/${data.image}`}
                                        alt=""
                                        className=""
                                      />
                                    </div>
                                    <h6 className="text-black">{data.title}</h6>
                                  </div>
                                </Link>
                              </Col>
                            ))}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Nav.Link>
              ))}
            </Nav>
            <div className="flex_center position-relative">
              <div className="icon_bg mx-md-2 mx-xl-0 me-xl-4">
                <HiIcons.HiOutlineSearch
                  size="1.4rem"
                  className="search_icon"
                  style={{ color: "black" }}
                />
              </div>

              {/* <div class="searchBox">
                <input
                  class="searchInput"
                  type="text"
                  name=""
                  placeholder="Search"
                />
                <button class="searchButton" type="submit" href="#">
                  <HiIcons.HiOutlineSearch
                    size="1.4rem"
                    className="search_icon "
                    style={{ color: "white" }}
                  />
                </button>
              </div> */}
              <div className="icon_bg mx-md-2 mx-lg-0 me-xl-4">
                <BsIcons.BsBell
                  size="1.4rem"
                  className="bell_icon "
                  style={{ color: "black" }}
                />
              </div>
              {localStorage.getItem("user_type") === null ? (
                <Nav.Link as={Link} to="/user/login">
                  <img
                    src={profileLogo}
                    alt=""
                    className="profile_logo img-fluid"
                  />
                </Nav.Link>
              ) : localStorage.getItem("user_type") !== null ? (
                <Dropdown>
                  <Dropdown.Toggle
                    className="profile_avatar"
                    id="dropdown-basic"
                  >
                    <img
                      src={require("../../Assets/profile-icon.png")}
                      alt=""
                      className="img-fluid cursor-pointer"
                      height={40}
                      width={40}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="user_dropdown_menu">
                    <Dropdown.Item
                      as={Link}
                      to="user-profile"
                      className="fw-bold"
                    >
                      {localStorage.getItem("name")}
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        localStorage.removeItem("name");
                        localStorage.removeItem("email");
                        localStorage.removeItem("oauth_type");
                        localStorage.removeItem("user_type");
                        localStorage.removeItem("user_id");
                        window.location.reload();
                        // localStorage.clear();
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Nav.Link
                  href="/"
                  onClick={() => {
                    setModalShow(true);
                  }}
                >
                  <img src={profileLogo} alt="" className="profile_logo " />
                </Nav.Link>
              )}
            </div>
          </Container>
        </Navbar>
      </div>
      {/* ====================== Sign In Modal =================== */}

      {/* <Modal
        show={signInFormDisplay === true ? modalShow : ""}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-block border-0">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            Sign in
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h6 className="fw-bold py-1 lh-base">
            You can share, save, or even discuss these interesting articles with
            millions of people from all around the world!
          </h6>
          <p>Just sign in with:</p>
          <div className="flex_between my-2">
            <Button className="w-75" style={{ borderRadius: "8px" }}>
              <FaIcons.FaFacebookF size="1.5em" />
            </Button>
           
            <GoogleLogin
              render={(renderProps) => (
                <Button
                  className="w-75 ms-3"
                  style={{ borderRadius: "8px" }}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <BsIcons.BsGoogle size="1.5em" />
                </Button>
              )}
              clientId="52699536198-l36ldpscdrqtske5ufqteg8f7dg4kfen.apps.googleusercontent.com"
              onSuccess={googleSuccess}
              onFailure={googleFailure}
              cookiePolicy="single_host_origin"
            />
          </div>
          <p className="">or with email</p>
          <Form onSubmit={handleSubmit}>
            <Form.Control
              type="text"
              className=" p-2 mb-2"
              placeholder="E-mail"
              style={{
                backgroundColor: "#f3f3f6",
                boxShadow: "none",
                border: "1px solid #f3f3f6",
                borderRadius: "8px",
                padding: "0 16px",
              }}
              ref={Email}
            />
            <Form.Control
              type="password"
              className="p-2"
              placeholder="Password"
              style={{
                backgroundColor: "#f3f3f6",
                boxShadow: "none",
                border: "1px solid #f3f3f6",
                borderRadius: "8px",
                padding: "0 16px",
              }}
              ref={Password}
            />
            <Button
              className="w-100 my-3"
              style={{
                backgroundColor: "#ffc40b",
                color: "#000",
                boxShadow: "none",
                border: "1px solid #ffc40b",
                borderRadius: "8px",
                padding: "8px 16px",
              }}
              type="submit"
            >
              Sign In
            </Button>
          </Form>
          <Link
            to="/"
            className="my-2 fs22 fw-bold text-black d-block"
            onClick={SignInControl}
          >
            New here? Sign up!
          </Link>
        </Modal.Body>
      </Modal> */}

      {/* ====================== Sign Up Modal =================== */}
      {/* <Modal
        show={signUpFormDisplay === true ? modalShow : ""}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-block border-0">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            Sign up
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            className=" p-2 mb-2"
            placeholder="Name"
            style={{
              backgroundColor: "#f3f3f6",
              boxShadow: "none",
              border: "1px solid #f3f3f6",
              borderRadius: "8px",
              padding: "0 16px",
            }}
          />
          <Form.Control
            type="text"
            className="p-2"
            placeholder="Email"
            style={{
              backgroundColor: "#f3f3f6",
              boxShadow: "none",
              border: "1px solid #f3f3f6",
              borderRadius: "8px",
              padding: "0 16px",
            }}
          />
          <Form.Check
            type="checkbox"
            id="default-checkbox"
            label="I agree to the processing of my personal data and terms of service"
            className="my-2"
          />
          <Button
            style={{
              width: "100%",
              backgroundColor: "#ffc40b",
              color: "#000",
              border: "1px solid #ffc40b",
              borderRadius: "8px",
              padding: "8px 16px",
            }}
            onClick={SignInControl}
          >
            Create an account
          </Button>
        </Modal.Body>
      </Modal> */}

      {/* =============================  Mobile Header ============================= */}
      <Navbar
        key="false"
        expand="false"
        className="mb-2 mobile_nav mobile_header flex-nowrap"
      >
        <Navbar.Toggle aria-controls="offcanvasNavbar-expand-false">
          <HiIcon.HiMenu size="1.5em" color="black" />
        </Navbar.Toggle>
        <Navbar.Brand href="/">
          <div className="site_logo_mbl">
            <img
              src={require("../../Assets/kepler-logo.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </Navbar.Brand>
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-false"
          aria-labelledby="offcanvasNavbarLabel-expand-false"
          placement="start"
        >
          <Offcanvas.Header closeButton className="d-flex align-items-center">
            <Navbar.Brand href="/">
              <div className="site_logo_mbl">
                <img
                  src={require("../../Assets/kepler-logo.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Navbar.Brand>
          </Offcanvas.Header>
          <Offcanvas.Body className="">
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <div className="nav_links_mbl">
                <Nav.Link
                  as={Link}
                  to="/"
                  className={`flex_center border border-white`}
                >
                  <FaIcons.FaRegLightbulb size=".8rem" />
                  <span className="ms-1 px-1">Popular</span>
                </Nav.Link>
                {navItem.map((data, i) => (
                  <NavDropdown title={data.name} key={i}>
                    <div className="dropdown_section p-0">
                      {data.category?.map((subcategory, j) => (
                        <NavDropdown.Item
                          as={Link}
                          to={`/category/${subcategory?.slug}`}
                          className=""
                          key={j}
                        >
                          <span>{subcategory?.name}</span>
                        </NavDropdown.Item>
                      ))}
                    </div>
                  </NavDropdown>
                ))}
              </div>

              <div className="nav_links_mbl">
                <Nav.Link href="/">Privacy Policy</Nav.Link>
                <Nav.Link href="/">Copyright policy</Nav.Link>
                <Nav.Link href="/">Cookies Policy</Nav.Link>
                <Nav.Link href="/">Terms of service</Nav.Link>
                <Nav.Link href="/">Sitemap</Nav.Link>
                <Nav.Link href="/">Update consent</Nav.Link>
              </div>
              <div className="nav_links_mbl py-2">
                <Nav.Link
                  href="/"
                  className="border border-1 border-top border-bottom py-3"
                >
                  <span className="px-1">Contact us</span>
                </Nav.Link>
              </div>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>

        <div className="flex_center">
          <div className="icon_bg">
            <BsIcons.BsBell
              size="1.2rem"
              className="bell_icon "
              style={{ color: "black" }}
            />
          </div>
          {/* <Nav.Link href="/">
            <CgIcon.CgProfile size="1.2rem" style={{ color: "black" }} />
          </Nav.Link> */}
          {localStorage.getItem("user_type") === null ? (
            <Nav.Link
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setModalShow(true);
              }}
            >
              <img src={profileLogo} alt="" className="profile_logo " />
            </Nav.Link>
          ) : localStorage.getItem("user_type") !== null ? (
            <Dropdown>
              <Dropdown.Toggle className="profile_avatar" id="dropdown-basic">
                <img
                  src={require("../../Assets/profile-icon.png")}
                  alt=""
                  className="img-fluid cursor-pointer ps-1 me-2 me-sm-4"
                  height={30}
                  width={30}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="#/action-1">
                  {localStorage.getItem("name")}
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    localStorage.removeItem("name");
                    localStorage.removeItem("email");
                    localStorage.removeItem("oauth_type");
                    localStorage.removeItem("user_type");
                    localStorage.removeItem("user_id");
                    window.location.reload();
                  }}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Nav.Link
              href="/"
              onClick={() => {
                setModalShow(true);
              }}
            >
              <img src={profileLogo} alt="" className="profile_logo " />
            </Nav.Link>
          )}
        </div>
      </Navbar>
    </>
  );
};
export default Header;
